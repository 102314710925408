export const breadcrumbList = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '现金券列表',
    path: '/main/coupons/list',
    query:''
  },
  {
    name: '新增营销券',
    path: ''
  }
]

export const rules = {
  name: [{ required: true, message: '输入券名称', trigger: 'blur' },
  { min: 2, max: 10, message: '输入2-10个字符', trigger: 'blur' },
],
  discount: [{ required: true, message: '输入优惠金额', trigger: 'blur' }],
  salePrice: [{ required: true, message: '输入售价', trigger: 'blur' }],
  hasThreshold: [{ required: true, message: '选择使用门槛', trigger: 'blur' }],
  shopIdList: [{ required: true, message: '选择归属门店', trigger: 'blur' }],
  total: [{ required: true, message: '输入总发放量', trigger: 'blur' }],
  validityType: [{ required: true, message: '选择有效期', trigger: 'blur' }],
  thresholdPrice: [
    { required: true, message: '输入使用门槛金额', trigger: 'blur' }
  ],
  limitNumber: [{ required: true, message: '输入限领数量', trigger: 'blur' }],
  days: [{ required: true, message: '有效期天数', trigger: 'blur' }],
  date:[
    { required: true, message: '选择有效期的开始和结束时间', trigger: 'blur' },
  ],
  // saleDate:[
  //   { required: true, message: '选择开始和结束时间', trigger: 'blur' },
  // ],
  weekList:[
    { required: true, message: '选择指定周期', trigger: 'blur' },
  ],
  serveIdList:[
    { required: true, message: '选择指定项目', trigger: 'blur' },
  ],
  shopIdList:[
    { required: true, message: '选择指定门店', trigger: 'blur' },
  ]
}

export const limitList = [
  {
    label: '不限制',
    value: 1
  },
  {
    label: '每人限制',
    value: 2
  },
  {
    label: '月度',
    value: 3
  },
  {
    label: '年度',
    value: 4
  },
]
export const weekList = [
  {
    label: '周一',
    value: 1
  },
  {
    label: '周二',
    value: 2
  },
  {
    label: '周三',
    value: 3
  },
  {
    label: '周四',
    value: 4
  },
  {
    label: '周五',
    value: 5
  },
  {
    label: '周六',
    value: 6
  },
  {
    label: '周日',
    value: 7
  }
]
